<template lang="">
    <div class="userGuide">
        <a-card :bordered="false" class="header-solid h-full p-20" :bodyStyle="{ padding: 0 }">
            <div class="userGuide__title">
                <h2>{{ $t('table_title.User_Guide') }}</h2>
                <a-input-search v-model="search" :placeholder="$t('header.search')" @change="onSearchItem" />
            </div>
            <div class="text-center py-5" v-if="isLoadingTutorial">
                <a-spin size="large" />
            </div>
            <div class="userGuide__list" v-if="!isLoadingTutorial">
                <div class="userGuide__item" v-for="(tutorial, index) in tutorialList" :key="index">
                    <router-link :to="{ path: `/user-guide/${tutorial.id}` }"> {{ tutorial.title }} </router-link>
                </div>
            </div>
            <div class="text-center py-5" v-if="!isLoadingTutorial && !tutorialList.length">
                <a-empty :description="$t('no_data')" />
            </div>
            <div class="text-center mt-5">
                <f-pagination
                    v-model="page"
                    :pageSize="tutorialPagination.pageSize"
                    :totalItems="tutorialPagination.totalItems"
                    :disabled="isLoadingTutorial"
                />
            </div>
        </a-card>
    </div>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            page: 1,
            search: '',
        };
    },
    async created() {
        this.getList();
    },
    computed: {
        ...mapState('tutorial', ['isLoadingTutorial', 'tutorialList', 'tutorialPagination']),
    },
    watch: {
        page(val) {
            this.getList({ page: val, field: 'title', search: this.search });
        },
    },
    methods: {
        ...mapActions('tutorial', ['getTutorialList']),

        async getList(data) {
            this.page = data?.page || 1;
            this.search = data?.search || '';
            await this.getTutorialList(data);
        },

        onSearchItem: _.debounce(function (e) {
            this.getList({ page: 1, field: 'title', search: this.search });
        }, 500),
    },
};
</script>

<style scoped lang="scss">
.userGuide__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    & h2 {
        font-size: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    & .ant-select {
        margin-right: 10px;
        width: 175px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.userGuide__item {
    & + & {
        margin-top: 15px;
    }
    & a {
        display: block;
        padding: 10px 15px;
        font-size: 16px;
        color: #000;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        transition: all 0.3s ease;
    }
    & a:hover {
        color: #1890ff;
    }
}
</style>
